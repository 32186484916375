<template>
  <div>
    <h2 class="mt-1 ml-15 hidden-sm-and-down grey--text">Cotización / Guias</h2> <!-- Full Screen -->
    <h3 class="mt-1 hidden-sm-and-up grey--text">Cotización / Guias</h3> <!-- Mobile Screen -->
    <div class="mt-5">
      <v-bottom-navigation :value="value" color="success">
        <v-btn @click="OpenComponent(0)" :disabled="comp0">
          <span>Cotización</span>
          <v-icon>{{CotizaIcon}}</v-icon>
        </v-btn>
        <v-btn @click="OpenComponent(1)" :disabled="comp1">
          <span>Guias</span>
          <v-icon>{{GuiaIcon}}</v-icon>
        </v-btn>
      </v-bottom-navigation>
      <div class="mt-2">
      
          <component v-bind:is="componente" :usu="usu" :contrato="contrato"></component>
      
      </div>
    </div>
    <dialogo :loading="loading"  />
  </div>
</template>
<script>
import axios from 'axios';
import Cotiza from '@/components/Cotiza.vue';
import Guias from '@/components/Guias.vue';
import dialogo from '@/components/dialogo.vue';
export default {
  name: 'Pedidos',
  components: { Cotiza, Guias, dialogo},
  props: ['usu','contrato'],
  data(){
    return{
      loading:false,
      value:null,
      componente:'',
      IconSelect:'check_circle',
      CotizaIcon:'receipt',
      GuiaIcon:'receipt',
      IconDefault:'receipt',
      comp0:true,
      comp1:true,
    }
  },
  async mounted(){
    await this.entrada();
  },
   methods:{
    async entrada(){
      this.loading=true;
      const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/usuarios/'+ this.contrato +'/'+ this.usu);
      const datos=response.data;
      if (datos[0].existe=='si'){
        //cotiza
        if(datos[0].Permiso_3_3!=0) {
          this.comp0=false;
        };
        //guia
        if(datos[0].Permiso_2_3!=0) {
          this.comp1=false;
        };

      } 
      this.moduloActivo();      
    },
    moduloActivo(){
      if (this.comp0!=true){
          this.componente='Cotiza';
          this.CotizaIcon=this.IconSelect;
          this.GuiaIcon=this.IconDefault;
          this.value=0
      } else {
          if (this.comp1!=true){
            this.componente='Guias';
            this.CotizaIcon=this.IconDefault;
            this.GuiaIcon=this.IconSelect;
            this.value=1
          } else {
            this.CotizaIcon=this.IconDefault;
            this.GuiaIcon=this.IconDefault;
          }
      }
      this.loading=false;
    },
    OpenComponent(value){
      let vComponente;
      switch (value) {
        case 0:
          vComponente='Cotiza';
          this.CotizaIcon=this.IconSelect;
          this.GuiaIcon=this.IconDefault;
          break;
        case 1:
          vComponente='Guias';
          this.CotizaIcon=this.IconDefault;
          this.GuiaIcon=this.IconSelect;
          break;
      }
      this.componente=vComponente;
    }
  }
}
</script>