const URLactual = window.location.href;
let result;
if(URLactual=='http://pwa.bodeganet.cl/' || 
    URLactual=='https://pwa.bodeganet.cl/' || 
    URLactual=='http://www.pwa.bodeganet.cl/' ||
    URLactual=='https://www.pwa.bodeganet.cl/'){
    result=true
} else {
    result=false
};
export default result