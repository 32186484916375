<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">COTIZACIÓN</v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="3" md="1">
                                <div class="text-center">
                                    <v-btn @click="editar()" color="cyan" fab x-small dark><v-icon>edit</v-icon></v-btn>                                    
                                </div>
                            </v-col>
                            <v-col cols="9" md="3">
                                <v-text-field class='text-caption' v-model="orden" label="Numero Orden" :disabled="soloOrden" :filled="soloOrden" outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="9" md="4">
                                <!-- <v-select v-if="pcotiza===true" class='text-caption' v-model="Cliente" :items="itemCliente" label="Cliente" :disabled="disabledOrden" :filled="filledOrden" dense outlined></v-select> -->
                                <v-combobox v-if="pcotiza===true" @change="numeroCliente(Cliente)" :hint="rutCliente" persistent-hint class='text-caption' v-model="Cliente" :items="itemCliente" label="Cliente" dense outlined disabled filled></v-combobox>
                                <v-text-field v-else class='text-caption' :hint="rutCliente" persistent-hint v-model="Cliente" label="Cliente" disabled filled outlined dense></v-text-field>
                            </v-col>
                            <v-col cols="3" md="1">
                                <v-btn v-if="pcotiza===true" @click="clientes=true" class="mt-1" small color="indigo" dark rounded><v-icon small>find_replace</v-icon></v-btn>
                                <!-- <v-btn v-if="pcotiza===true" @click="solicitaOrden(Cliente)" class="mt-1" small color="teal" dark rounded><v-icon small>add</v-icon></v-btn> -->
                                <v-btn v-else @click="buscaOrden(orden)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                    Orden Existe !!!
                                </v-alert>
                                <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                    Nueva Orden !!!
                                </v-alert>
                                <v-alert class='text-caption' v-else dense text type="info">
                                    Selecciona Datos !!!
                                </v-alert>
                            </v-col >
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="12" md="1"></v-col>
                            <v-col cols="12" md="4">
                                <v-text-field class='text-caption' v-model="direction" label="Direccion" outlined dense disabled filled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-text-field class='text-caption' v-model="contact" label="Contacto" outlined dense disabled filled></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <div v-if="itemsDirections.length > 1 || itemsContacts.length > 1" class="mt-2">
                                    <v-btn @click="openDirecciones()" small rounded color="teal" dark>
                                        <span class='text-btn'>Dirección y Contacto</span>
                                    </v-btn>
                                </div>
                                <div v-else class="mt-2">
                                    <v-btn small rounded color="grey" dark>
                                        <span class='text-btn'>Dirección y Contacto</span>
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                        <v-divider></v-divider>
                        <v-row class="mt-2">
                            <!-- <v-col cols="2" md="1" style="margin-top:-10px">
                                <v-checkbox class="float-end" @click="CheckBarra()" v-model="checkbox" :disabled="disabledDatos"></v-checkbox>
                            </v-col>
                            <v-col cols="10" md="2">
                                <v-combobox id="barr" v-on:change="search_BarraItem(listabarra)" class='text-caption' :items="itemProductos" v-model="listabarra" item-text="bar"  label="Codigo Barra" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-combobox>
                            </v-col> -->
                            <v-col cols="12" md="1"></v-col>
                            <v-col cols="10" md="4">
                                <v-combobox :hint="SkuItem" persistent-hint v-on:change="search_SkuItem(listaproducto)" class='text-caption' v-model="listaproducto" :items="itemProductos" item-text="name"  label="Producto" dense outlined disabled filled></v-combobox>
                            </v-col>
                            <v-col cols="2" md="1">
                                <v-btn v-if="disabledBarra===true" class="mt-1" small color="grey" dark rounded><v-icon small>find_replace</v-icon></v-btn>
                                <v-btn v-else @click="inventa=true" class="mt-1" small color="indigo" dark rounded><v-icon small>find_replace</v-icon></v-btn>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-select v-on:change="buscaPrecio(listacodigo)" class='text-caption' v-model="miprecio" :items="itemsPrecio" label="Tipo Precio" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                             </v-col>
                            <v-col cols="12" md="2">
                                <v-text-field class='text-caption' @keypress="onlyNumber" v-model="precio" label="Precio" outlined dense required disabled filled></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n5">
                            <v-col cols="12" md="1"></v-col>
                            <v-col cols="12" md="2">
                                <v-select class='text-caption' v-on:change="stockSku(bodega)" v-model="bodega" :items="itemBodegas" label="Local" dense outlined :hint="tStock" persistent-hint :disabled="disabledDatos" :filled="filledDatos"></v-select>                                
                            </v-col>
                            <v-col cols="8" md="2">
                                <v-text-field v-if="txtMedida!='Medida: UN'" class='text-caption' @keypress="onlyDecimal" v-model="cantidad" label="Cantidad" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                <v-text-field v-else class='text-caption' @keypress="onlyNumber" v-model="cantidad" label="Cantidad" outlined dense required clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                <div class="mt-n7 ml-3"><span class="text-caption grey--text">{{txtMedida}}</span></div>
                            </v-col>
                            <v-col cols="4" md="1">
                                <v-icon class="mt-2" v-if="txtMedida=='Medida: KG' || txtMedida=='Medida: LT'" @click="convierteFila()" color="success">track_changes</v-icon>
                                <v-icon class="mt-2" v-else color="grey">track_changes</v-icon>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn  @click="saveVenta()" class="mt-1" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar Producto</span></v-btn>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-alert style="height:40px;" class='text-body-2' dense text border="left" color="red">
                                    <div class="mt-1 float-left">
                                        Total
                                    </div>
                                    <div class="mt-1 float-right">
                                        ${{new Intl.NumberFormat("de-DE").format(total)}}
                                    </div>                                             
                                </v-alert>
                                <!-- <v-alert class='text-caption' dense text border="left" color="warning">Total $ {{venta.toLocaleString()}}</v-alert>  -->
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions style="margin-top:-25px">
                <v-btn class="mt-n2" @click="dialogVentas()" color="indigo" fab dark>{{nVentas}}</v-btn>
                <h3 class="ml-2 text-caption">Total Items</h3>

                <v-btn class="ml-5 mr-5" color="teal" icon
                        v-clipboard:copy="copiar" v-clipboard:success="onCopy" v-clipboard:error="onError">
                        <v-icon>content_copy</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field class='text-caption' v-model="observa" label="Observacion" outlined dense required clearable></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-btn  @click="saveObserva()" class="mt-1" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Confirmar Obs.</span></v-btn>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-btn @click="newVenta()" class="mt-1" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nueva Cotización</span></v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

        <!-- dialog items --> 
        <v-dialog v-model="dialog" transition="dialog-bottom-transition" max-width="80%" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="dialog=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer></v-spacer>
                <a target="_blank" :href="html" style="text-decoration: none"><v-icon>print</v-icon></a>
            </v-card-title>
            <v-card-text>
                <v-data-table dense dark :search="likesearch" :headers="headers" :items="items" :page.sync="page" :items-per-page="itemsPerPage" hide-default-footer class="elevation-1" @page-count="pageCount = $event">
                    <template v-slot:item.accion="{ item }">
                        <v-icon small @click="deleteVenta(item.id)">delete</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col cols="12" md="6">
                        <v-pagination v-model="page" :length="pageCount"></v-pagination>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-text-field class='ml-5 mr-2 text-caption' v-model="likesearch" label="Buscar Producto" dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2" class='mt-3'>
                        <a class="mt-2 ml-2" v-if="descargaCSV===false" href="javascript:void(0)" @click="fileCSV()"><img src="@/assets/csv.png" width="16px" height="20px"/></a>
                        <a class="ml-2" v-else :href="rutaCSV" style="text-decoration: none"><v-icon color='teal'>cloud_download</v-icon><span class="ml-2 text-caption">Descarga</span></a>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- dialog inventa --> 
        <v-dialog v-model="inventa" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="inventa=false" color="red" small dark><v-icon small>close</v-icon>Close</v-btn>
                <v-spacer>
                    <div class="float-right">
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="inventasearch" label="Buscar Producto" dense required></v-text-field>
                    </div>
                </v-spacer>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="handleClick" dense dark :search="inventasearch" :headers="inventatitulo" :items="inventalista" :page.sync="inventapage" :items-per-page="inventaitemsPerPage"  @page-count="inventapageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="inventapage" :length="inventapageCount"  :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- dialog clientes --> 
        <v-dialog v-model="clientes" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="clientes=false" color="red" small dark><v-icon small>close</v-icon></v-btn>
                <v-spacer>
                    <div class="float-right">
                    <v-text-field class='ml-5 mr-2 text-caption' v-model="clientesearch" label="Buscar Cliente" dense required></v-text-field>
                    </div>
                </v-spacer>
            </v-card-title>
            <v-card-text>
                <v-data-table @click:row="handleClientes" dense dark :search="clientesearch" :headers="clientetitulo" :items="clientelista" :page.sync="clientepage" :items-per-page="clienteitemsPerPage"  @page-count="clientepageCount = $event" hide-default-footer class="elevation-1 text-size">
                </v-data-table>
                <v-pagination v-model="clientepage" :length="clientepageCount" :total-visible="5"></v-pagination>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- dialog convierte --> 
        <v-dialog v-model="convierte" transition="dialog-bottom-transition" max-width="600" persistent>
        <v-card>
            <v-btn class="mt-2 ml-2" @click="convierte=false" color="red" small dark><v-icon small>close</v-icon></v-btn>
            <v-card-title>
                Convertir Unidad de Medida ( 1 x ${{valorKG}} )
            </v-card-title>
            <v-card-text>
                <v-container>
                <v-row>
                    <v-col cols="12" md="3" class="text-center">
                        <v-text-field @keypress="onlyDecimal" v-model="cantidadKG" label="Cantidad" dense outlined :filled="filConvierte" :disabled="disConvierte"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                        <v-btn @click="cantidad_precio()" dark color="indigo"><v-icon>fast_forward</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                        <v-btn @click="precio_cantidad()" dark color="indigo"><v-icon>fast_rewind</v-icon></v-btn>
                    </v-col>
                    <v-col cols="12" md="3" class="text-center">
                        <v-text-field @keypress="onlyNumber" v-model="precioKG" label="Precio" dense outlined :filled="filConvierte" :disabled="disConvierte"></v-text-field>                        
                    </v-col>
                </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="mt-n10">
                <v-btn @click="no_aplica()" color="success" small>LIMPIAR</v-btn>
                <v-spacer></v-spacer>
                <v-btn v-if="btnAplica==true" @click="si_aplica()" color="success" small>APLICAR</v-btn>
                <v-btn v-else color="grey" small>APLICAR</v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- dialog direcciones y contactos --> 
        <v-dialog v-model="d_directions" transition="dialog-bottom-transition" max-width="400" persistent>
        <v-card>
            <v-card-title>
                <v-btn @click="d_directions=false" color="red" small dark><v-icon small>close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-select class='text-caption' v-model="myDireccion" :items="itemsDirections" item-text="direccion" item-value="direccion" label="Direccion" dense outlined></v-select>
                    <v-select class='text-caption' v-model="myContacto" :items="itemsContacts" item-text="contacto" item-value="contacto" label="Contacto" dense outlined></v-select>
                </v-container>
                <v-row>
                <v-col cols="12" md="12" class="text-center">
                    <v-btn @click="cambiarDireccion()" small rounded color="teal" dark>
                        <span class='text-btn'>Cambiar</span>
                    </v-btn>
                </v-col>
            </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>
        <!-- dialogo Guia de Despacho -->
        <v-dialog v-model="d_guia" width="320" persistent>
            <v-card>
                <v-card-title>
                    <v-icon color="warning">warning</v-icon>
                </v-card-title>
                <v-card-text>
                    <span class="mt-2 text-body-2">Orden con Guia de Despacho: N° <strong>{{ arrayGuia.Numero }}</strong></span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="newVenta()" color="error" small rounded>cerrar</v-btn>
                </v-card-actions>
            </v-card>
         </v-dialog>

        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />        
    </div>
</template>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Venta', 
    components:{dialogo, snack},
    props: ['usu','contrato'],   
    data(){
        return{
            pcotiza:true,
            hoy:'',
            //contrato:'123',
            observa:'',
            tStock:'',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            existe:'',
            disabledOrden:false,
            filledOrden:false,
            disabledDatos:true,
            filledDatos:true,
            disabledCheck:true,
            filledCheck:true,
            disabledBarra:true,
            filledBarra:true,
            soloOrden:true,
            btnGrabar:true,
            orden:'',
            itemCliente:[],
            arrayCliente:[],
            Cliente:'',
            listaproducto:'',
            listabarra:'',
            listanombre:'',
            listacodigo:'',
            itemProductos:[],
            arrayProductos:[],
            bodega:'',
            itemBodegas:[],
            arrayBodegas:[],
            precio:'',
            cantidad:'',
            radios:'Neto',
            total:0,
            nCliente:0,
            nProducto:0,
            nBodega:0,
            cv:'V',
            nVentas:0,
            // dialog Ventas
            dialog:false,
            likesearch:'',
            page: 1,
            pageCount: 0,
            itemsPerPage: 8,
            headers: [
                { text: 'Sku', align: 'start', sortable: true, value: 'sku' },
                { text: 'Producto', align: 'start', sortable: true, value: 'producto' },
                { text: 'Precio', align: 'center', sortable: true, value: 'precio' },
                { text: 'Cantidad', align: 'center', sortable: true, value: 'cantidad' },
                { text: 'Medida', align: 'center', sortable: true, value: 'medida' },
                { text: 'Bodega', align: 'center', sortable: true, value: 'bodega' },
                { text: 'Accion', align: 'center', sortable: true, value: 'accion' },
            ],
            items: [],
            itemsPrecio:['Precio Detalle','Precio x Mayor','Precio Oferta'], 
            miprecio:'',
            html:'',
            descargaCSV:false,
            rutaCSV:'',
            SkuItem:'',
            nStock:0,
            copiar:'',
            checkbox:false,
            rutCliente:'',
            rutSearch:'',
            impuesto:'',
            venta:0,
            inventa:false,
            inventasearch:'',
            inventatitulo:[
                { text: 'Sku', align: 'start', sortable: true, value: 'iSku', width:'' },
                { text: 'Producto', align: 'start', sortable: true, value: 'iProducto' },
                { text: 'Medida', align: 'start', sortable: true, value: 'iMedida' },
            ],
            inventalista:[],
            inventapage: 1,
            inventapageCount: 0,
            inventaitemsPerPage: 10,
            txtMedida:'',

            //dialogo clientes
            clientes:false,
            clientesearch:'',
            clientetitulo:[
                { text: 'Rut', align: 'start', sortable: true, value: 'rut' },
                { text: 'Cliente', align: 'start', sortable: true, value: 'nombre' },
            ],
            clientelista:[],
            clientepage: 1,
            clientepageCount: 0,
            clienteitemsPerPage: 10,

            // Convierte
            filaConvierte:[],
            convierte:false,
            valorKG:0,
            upCantidad:0,
            cantidadKG:0,
            precioKG:0,
            filConvierte:false,
            disConvierte:false,
            btnAplica:false,
            precioCliente:'',
            direction:'',
            contact:'',
            d_directions:false,
            myDireccion:'',
            myContacto:'',
            itemsDirections:[],
            itemsContacts:[],
            d_guia:false,
            arrayGuia:[],

        }
    },
    async mounted(){
        await this.buscaCliente();
        await this.buscarProducto();
        await this.buscaImpuesto();
        //await this.buscarBodegas()
        //console.log(this.usu);
    },
    methods:{
        async saveObserva(){
            try {
                if(this.orden!=''){
                    const params={
                        contrato : this.contrato,
                        orden: this.orden,
                        observa: this.observa,
                    }
                    const response = await axios.put(process.env.VUE_APP_URL + '/api/inv/observa/cotiza/',params);
                    const datos=response.data;
                    if(datos=='si'){
                        this.Msg='La Observacion o referencia fue confirmada con exito';
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } else {
                        this.Msg='La Observacion o referencia no fue confirmada, intentelo mas tarde';
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }
                } else {
                    this.Msg='Ingrese la Observacion o Referncia';
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
                }
            } catch (error) {
                console.log(error)
                this.Msg='La Observacion o referencia no fue confirmada, intentelo mas tarde';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async cambiarDireccion(){
            this.loading=true;
            try {
                let varDireccion='';
                let varComuna='';
                let varCiudad='';
                let varContacto='';
                let varTelefono='';
                let varEmail='';
                const filter = this.clientelista.filter(res => res.entidad == this.nCliente);
                //obtener data del direccion
                varDireccion = filter[0].direccion;
                varComuna = filter[0].comuna;
                varCiudad = filter[0].ciudad;
                //obtener data del contacto
                varContacto = filter[0].contacto;
                varTelefono = filter[0].telefono;
                varEmail = filter[0].email;

                if(this.itemsDirections.length!=0){
                    if(this.myDireccion!=''){
                        varDireccion=this.myDireccion
                        const found = this.itemsDirections.filter(res => res.direccion == this.myDireccion);
                        varComuna = found[0].comuna;
                        varCiudad = found[0].ciudad;
                    } 
                };
                if(this.itemsContacts.length!=0){
                    if(this.myContacto!=''){
                        varContacto=this.myContacto;
                        const found = this.itemsContacts.filter(res => res.contacto == this.myContacto);
                        varTelefono = found[0].fono;
                        varEmail = found[0].email;

                    }
                };

                const params={
                    entidad: this.nCliente,
                    contrato : this.contrato,
                    direccion: varDireccion,
                    comuna: varComuna,
                    ciudad: varCiudad,
                    contacto: varContacto,
                    telefono: varTelefono,
                    email: varEmail
                }
                const response = await axios.put(process.env.VUE_APP_URL + '/api/inv/clientes/direction/contact/',params);
                const datos=response.data;
                if(datos=='si'){
                    this.direction = varDireccion;
                    this.contact = varContacto;
                    await this.buscaCliente();
                }
            } catch (error) {
                console.log(error)
            }
            this.loading=false;
            this.d_directions=false
        },
        async openDirecciones(){
            this.myDireccion='';
            this.myContacto='';
            this.d_directions=true;
        },
        async direcciones(){
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/clientes/direcciones/'+this.rutSearch+'/'+this.contrato);
                const datos=response.data;
                this.itemsDirections=[];
                if (datos[0].existe=='si'){
                    for (let index = 0; index < datos.length; index++) {
                        const element = datos[index];
                        this.itemsDirections.push({
                            id: element.ID,
                            direccion: element.Direccion,
                            comuna: element.Comuna,
                            ciudad: element.Ciudad
                        })
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async contactos(){
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/clientes/contactos/'+this.rutSearch+'/'+this.contrato);
                const datos=response.data;
                this.itemsContacts=[];
                if (datos[0].existe=='si'){
                    for (let index = 0; index < datos.length; index++) {
                        const element = datos[index];
                        this.itemsContacts.push({
                            id: element.ID,
                            contacto: element.Contacto,
                            fono: element.Telefono,
                            email: element.Email
                        })
                    }
                }
            } catch (error) {
                console.log(error)
            }
        },
        async convierteFila(){
            if(this.precio.trim()!='' && this.tStock.trim()!=''){
                this.convierte=true;
                this.valorKG=this.precio;
                this.cantidadKG=1;
                this.precioKG=this.precio;
                this.filConvierte=false;
                this.disConvierte=false;
                this.btnAplica=false;
            } else {
                this.Msg='Ingrese Precio y Local';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        cantidad_precio(){
            if(this.cantidadKG.trim()!=''){
                if(!isNaN(this.cantidadKG)){
                    //aplicamos regla de 3
                    let valor=parseFloat(this.cantidadKG)*parseFloat(this.valorKG);
                    valor=parseInt(valor);
                    this.precioKG=valor;
                    this.upCantidad=this.cantidadKG;
                    this.filConvierte=true;
                    this.disConvierte=true;
                    this.btnAplica=true;
                } else {
                    this.Msg='Cantidad Invalida';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    this.cantidad='';
                };
            }
        },
        precio_cantidad(){
            if(this.precioKG.trim()!=''){
                if(!isNaN(this.precioKG)){
                    //aplicamos regla de 3
                    let valor=parseFloat(this.precioKG)/parseFloat(this.valorKG);
                    this.upCantidad=valor;
                    valor=parseFloat(valor).toFixed(3);
                    this.cantidadKG=valor;
                    this.filConvierte=true;
                    this.disConvierte=true;
                    this.btnAplica=true;
                } else {
                    this.Msg='Cantidad Invalida';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    this.cantidad='';
                };                
            }
        },
        no_aplica(){
            this.upCantidad=0;
            this.cantidadKG=1;
            this.precioKG=this.valorKG;
            this.filConvierte=false;
            this.disConvierte=false;
            this.btnAplica=false;
        },
        si_aplica(){
            if(!isNaN(this.upCantidad)){
                this.cantidad=this.cantidadKG;
                this.convierte=false
            } else {
                 this.no_aplica()
            }
        },
        async handleClick(txtOpcion) {
            this.listanombre=txtOpcion.iProducto;
            this.listaproducto=txtOpcion.iProducto;
            this.listacodigo=txtOpcion.iSku;
            this.txtMedida='Medida: '+txtOpcion.iMedida;
            this.SkuItem="Sku: "+this.listacodigo;
            this.nProducto=this.listacodigo;
            await this.skuBodegas();
            this.miprecio=this.precioCliente;
            await this.buscaPrecio(txtOpcion.iSku);
            this.inventa=false;
        },
        async buscaImpuesto(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/impuesto/'+this.contrato);
            const datos=response.data;
            this.impuesto=0;
            if (datos[0].existe=='si'){
                this.impuesto=datos[0].Iva
            }
        },
        async fileCSV(){
            this.loading=true;
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/fileCsv/cotiza/'+this.contrato+'/'+this.orden+'/'+this.nCliente);
            const datos=response.data;
            //console.log(datos);
            if (datos=='si'){
                this.descargaCSV=true;
                this.rutaCSV=process.env.VUE_APP_URL + '/buzon/'+this.contrato+'/'+this.orden+'.csv';
            }
            this.loading=false;
        },
        editar(){
            this.newVenta();
            this.observa='';
            this.pcotiza=false;
            this.soloOrden=false;
        },

            onCopy: function (e) {
                this.Msg='Orden '+ e.text +' Copiada';
                this.msgbox=true;
                this.color='orange';
                this.showSnack();
            },
            onError: function (e) {
                this.Msg='Orden NO Copiada';
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            },

        async buscaOrden(nOrden){
            this.loading=true;
            try {
                if(this.orden.trim()!=''){
                    const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/orden/'+this.contrato+'/'+nOrden);
                    const datos=response.data;
                    if (datos[0].existe=='si'){
                        this.existe='si' 
                        this.soloOrden=true;
                        this.disabledDatos=false;
                        this.filledDatos=false;
                        this.disabledBarra=false;
                        this.filledBarra=false;
                        this.Cliente=datos[0].Nombre;
                        this.numeroCliente(this.Cliente);
                        this.observa=datos[0].ObservaCot;
                        let newTotal=0;
                        this.nVentas=0;
                        datos.forEach(element => {
                            newTotal=newTotal+parseFloat(element.Total); 
                            this.nVentas=parseFloat(this.nVentas)+parseFloat(element.Reg);                        
                        });
                        this.total=newTotal;
                        this.venta=newTotal+Math.round((newTotal*parseFloat(this.impuesto)/100));
                        this.copiar=''+nOrden;
                        //verificara si la orden tiene guia de despacho
                        const conGuia = await this.verificaGuia(nOrden);
                        if(conGuia=='si'){
                            this.d_guia = true
                        }
                    } else {
                        this.existe='';
                        this.Msg='Orden no Existe o con DTE';
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    } 
                } else {
                    this.existe='';
                    this.Msg='Ingrese Orden';
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } catch (error) {
                console.log(error)
            }
            this.loading=false;
        },
        async verificaGuia(nOrden){
            let result = 'no';
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/verify/guide/'+ this.contrato +'/'+ nOrden);
                const datos=response.data;
                if (datos[0].existe=='si'){
                    this.arrayGuia = datos[0];
                    result = 'si';
                } else { 
                    result = 'no' 
                }
            } catch (error) {
                console.log(error);
                result = 'no'
            }
            return result
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        onlyDecimal ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        dialogVentas(){
            if (this.nCompras!=0){
                this.dialog=true;
                this.descargaCSV=false;
                this.rutaCSV='';
                this.datosDialog()
            }
        },
        async datosDialog(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/orden/entidad/'+this.cv+'/'+this.contrato+'/'+this.orden+'/'+this.nCliente);
            const datos=response.data;
            let newTotal;
            newTotal=0;
            this.items=[];
            if (datos[0].existe=='si'){   
                this.html=process.env.VUE_APP_URL + '/api/inv/prt/cotiza/v5/'+this.contrato+'/'+this.orden+'/'+this.nCliente;
                for (let index = 0; index < datos.length; index++) {
                    const element = datos[index];
                    const tMedida=await this.searchMedida(element.Sku);
                    //console.log(element.Cantidad);
                    this.items.push({
                        'id':element.ID,
                        'sku':element.Sku,
                        'producto':element.Producto,
                        'precio':new Intl.NumberFormat("de-DE").format(parseFloat(element.Precio)),
                        'cantidad':new Intl.NumberFormat("de-DE").format(parseFloat(element.Cantidad)),
                        'medida':tMedida,
                        'bodega':element.Bodega,
                    })
                    newTotal=newTotal+(parseFloat(element.Precio)*parseFloat(element.Cantidad));
                }
            }
            this.total=newTotal;
            this.venta=Math.round(newTotal+(newTotal*parseFloat(this.impuesto)/100));
        },
        async searchMedida(mSku){
            try {
                const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/unidad/medida/'+ this.contrato +'/'+ mSku);
                const datos=response.data;
                if (datos[0].existe=='si'){ 
                    return datos[0].Medida;
                } else { return 'Sin Medida' }
            } catch (error) {
                return 'Sin Medida'
            }
        },
        async deleteVenta(n){
            this.loading=true;
            const response = await axios.delete(process.env.VUE_APP_URL + '/api/inv/orden/entidad/delete/'+this.contrato+'/'+n);
            const datos=response.data;
            if (response.data=='ok'){
                await this.datosDialog()
                this.nVentas=this.nVentas-1
            }           
            this.loading=false;
        },
        clsInput(){
            this.tStock='';
            this.SkuItem='';
            this.disabledOrden=false;
            this.filledOrden=false;
            this.disabledDatos=true;
            this.filledDatos=true;
            this.disabledCheck=true;
            this.filledCheck=true;
            this.disabledBarra=true;
            this.filledBarra=true;
            this.soloOrden=true; 
            this.listaproducto='';    
            this.listabarra='';       
            this.listanombre='';
            this.listacodigo='';
            this.precio='';
            this.cantidad='';
            //this.observa='';
            this.nCliente=0;
            this.nProducto=0;
            this.bodega='';
            this.nBodega=0;
            this.existe='';
            this.nVentas=0;
            this.dialog=false;
            this.items=[];
            this.pcotiza=true;
            this.hoy='';
            this.html='';
            this.miprecio='';
            this.precio='';
            this.descargaCSV=false;
            this.rutaCSV='';
            this.nStock=0;
            this.copiar='';
            this.checkbox=false;
            this.rutCliente='';
            this.rutSearch='';
            this.inventasearch='';
            this.txtMedida='';
            this.direction='';
            this.contact='';
            this.itemsDirections=[];
            this.itemsContacts=[];
            this.d_guia=false;
            this.arrayGuia=[];
        },
        newVenta(){
            this.observa='';
            this.orden='';
            this.Cliente='';
            this.total=0;
            this.venta=0;
            this.clsInput()
        },
        async buscaCliente(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/lista/clientes/'+this.contrato);
            const datos=response.data;
            console.log(datos);
            this.itemCliente=[];
            this.arrayCliente=[];
            this.clientelista=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemCliente.push(element.Nombre);
                    this.arrayCliente.push({id:element.ID,nombrecli:element.Nombre,rutcli:element.Rut});
                    this.clientelista.push({
                        entidad:element.ID,
                        nombre:element.Nombre,
                        rut:element.Rut,
                        email:element.Email,
                        telefono:element.Telefono,
                        direccion:element.Direccion,
                        comuna:element.Comuna,
                        ciudad:element.Ciudad,
                        giro:element.Observacion,
                        precio:element.Precio,
                        contacto:element.Contacto
                    })                
                });
            }
        },
        async handleClientes(fila) {
            this.Cliente=fila.nombre;
            this.precioCliente=fila.precio;
            await this.numeroCliente(fila.nombre);
            await this.solicitaOrden(fila.nombre);
            this.clientes=false
        },
        async buscarProducto(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/productos/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemProductos=[];
            this.arrayProductos=[];
            this.inventalista=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemProductos.push({"name":element.Nombre,"code":element.Sku,"bar":element.Barra,"med":element.Medida});
                    this.arrayProductos.push({
                        sku:element.Sku,
                        nameSku:element.Nombre,
                        precioSku:element.PrecioLocal,
                        precioMayor:element.PrecioMayor,
                        precioOferta:element.PrecioOferta
                    });
                    this.inventalista.push({'iSku':element.Sku,'iProducto':element.Nombre,'iMedida':element.Medida});
                });
            }
        },
        async buscarBodegas(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/bodegas/'+this.contrato);
            const datos=response.data;
            //console.log(datos);
            this.itemBodegas=[];
            this.arrayBodegas=[];
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemBodegas.push(element.Nombre);
                    this.arrayBodegas.push({numBodega:element.ID,nameBodega:element.Nombre})        
                });
            }
        },
        CheckBarra(){
            this.listaproducto='';
            this.listanombre='';
            this.listacodigo='';
            this.SkuItem='';
            this.nProducto=0;
            if (this.checkbox===false){
                this.disabledCheck=true;
                this.filledCheck=true;
                this.disabledBarra=false;
                this.filledBarra=false;
                this.listabarra='';
            } else {
                this.disabledCheck=false;
                this.filledCheck=false;
                this.disabledBarra=true;
                this.filledBarra=true;
            }
        },
        search_SkuItem(itemPro){ // variable Global
            //console.log(itemPro);
            this.listanombre=itemPro.name;
            this.listacodigo=itemPro.code;
            this.txtMedida='Medida: '+itemPro.med;
            this.SkuItem="Sku: "+this.listacodigo;
            this.nProducto=this.listacodigo;
            this.skuBodegas();
        },
        search_BarraItem(codBarra){ // variable Global
            //console.log(codBarra);
            this.listaproducto='';
            this.listanombre='';
            this.listacodigo='';
            this.SkuItem='';
            this.nProducto=0;
            var elemento = document.getElementById("barr");
            elemento.blur();
            const resArray = this.itemProductos.find( datoArray => datoArray.bar === codBarra );
            if (resArray){
                this.listaproducto=resArray.name;
                this.listanombre=resArray.name;
                this.listacodigo=resArray.code;
                this.txtMedida="Medida: "+resArray.med;
                this.SkuItem="Sku: "+this.listacodigo;
                this.nProducto=this.listacodigo;
                this.skuBodegas();
            }
        },
        valorSku(SkuProducto){
            this.arrayProductos.forEach(element => {
                if (SkuProducto==element.sku){
                    switch (this.miprecio) {
                        case 'Precio Detalle':
                            this.precio=element.precioSku
                            break;
                        case 'Precio x Mayor':
                            this.precio=element.precioMayor
                            break;
                        case 'Precio Oferta':
                            this.precio=element.precioOferta
                            break;
                        default:
                            this.precio=''
                            break;
                    }
                }
            });
        },
        async numeroCliente(txtCliente){
            this.arrayCliente.forEach(element => {
                if (txtCliente==element.nombrecli){
                    this.nCliente=element.id;
                    this.rutCliente="Rut: "+element.rutcli;
                    this.rutSearch=element.rutcli;
                }
            });
            //buscamos la direccion y contacto del cliente
            const filter = this.clientelista.filter(res => res.entidad == this.nCliente);
            this.direction=filter[0].direccion;
            this.contact=filter[0].contacto
            //agregamos a la lista 
            await this.direcciones();
            await this.contactos();
        },
        async numeroBodega(txtBodega){
            this.arrayBodegas.forEach(element => {
                if (txtBodega==element.nameBodega){
                    this.nBodega=element.numBodega
                }
            });
        },
        async stockSku(a){
            this.cantidad='';
            await this.numeroBodega(a);
            if (this.nBodega!=0){
                await this.totalStock();
            } else {
                this.tStock='Error de Bodega';
            }
        },
        async totalStock(){
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/stock/bodegas/producto/total/'+ this.contrato +'/'+ this.nBodega + '/' + this.nProducto);
            const datos=response.data;
            if (datos[0].existe=='si'){ 
                if(this.txtMedida!='Medida: UN'){
                    this.tStock='Total Stock: '+parseFloat(datos[0].Total).toFixed(3);
                } else {
                    this.tStock='Total Stock: '+parseInt(datos[0].Total);
                };
                this.nStock=datos[0].Total;
            }
            if (datos[0].existe=='no'){ 
                this.tStock='Sin Stock';
                this.nStock=0;
            } 
            if (datos[0].existe=='error'){ 
                this.tStock='Error de Stock';
                this.nStock=0;
            } 
        },
        async solicitaOrden(tCliente){
            this.clsInput();
            this.loading=true;
            const timestamp = Date.now();
            this.copiar=''+timestamp;
            //console.log(timestamp);
            this.orden=timestamp;
            const nOrden=timestamp;
            this.total=0;
            this.venta=0;
            if (Number.isInteger(nOrden/1)){
                if (tCliente.trim()!=''){
                    this.numeroCliente(tCliente);
                    this.disabledOrden=true;
                    this.filledOrden=true;
                    this.disabledDatos=false;
                    this.filledDatos=false;
                    this.disabledBarra=false;
                    this.filledBarra=false;
                    this.existe='no'                        
                } else {
                    this.Msg='Seleccione Cliente'
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
                    this.newVenta()
                    this.existe=''                        
                }
            } else {
                this.Msg='Orden no Numerica'
                this.msgbox=true;
                this.color='info';
                this.showSnack();
                this.newVenta()
                this.existe=''                        
            }
            this.loading=false;
        },
        async buscaPrecio(SkuProducto){
            //console.log(SkuProducto);
            this.precio='';
            this.valorSku(SkuProducto);
        },
        async skuBodegas(){
            this.bodega='';
            this.itemBodegas=[];
            this.arrayBodegas=[];
            this.nBodega=0;
            this.tStock='';
            this.cantidad='';
            //this.observa='';
            this.miprecio='';
            this.precio='';
            const response = await axios.get(process.env.VUE_APP_URL + '/api/inv/stock/bodegas/producto/'+ this.contrato +'/'+ this.nProducto);
            const datos=response.data;
            //console.log(datos);
            datos.forEach(element => {
                this.itemBodegas.push(element.Nombre);
                this.arrayBodegas.push({numBodega:element.ID,nameBodega:element.Nombre})        
            });
        },
        saveVenta(){
            this.loading=true;
            if(!isNaN(this.cantidad)){
                this.cantidad=this.cantidad;
            } else {
                this.cantidad='';
            }; 
            if (this.contrato!='' && this.orden!='' && this.Cliente!='' && 
                this.bodega!='' && this.cantidad!='' && this.precio!=''){
                if (this.nProducto!=0 && this.nCliente!=0 && this.nBodega!=0 && this.listanombre!=''){
                    if(this.usu.toUpperCase()!='ADMIN'){
                        if(this.nStock>=1 && this.nStock>=parseFloat(this.cantidad)){
                            this.save();
                        } else {
                            this.Msg='Producto Sin Stock'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } else {
                        console.log('el usuario ADMIN no verifica stock');
                        this.save();
                    }
                } else {
                    this.Msg='Intentelo Nuevamente'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                }
            } else {
                this.Msg='Datos sin Completar'
                this.msgbox=true;
                this.color='info';
                this.showSnack();
            }
            this.loading=false;
        },
        async save(){
            let canti=0;
            if(this.txtMedida!='Medida: UN'){
                canti=this.upCantidad;
            } else {
                canti=this.cantidad;
            };
            var parametros={
                'contrato': this.contrato,
                'orden': this.orden,
                'entidad': this.nCliente,
                'tipo': this.radios,
                'cv': this.cv,
                'sku':this.nProducto,
                'producto':this.listanombre,
                'precio':this.precio,
                'cantidad':canti,
                'bodega': this.nBodega,
                'observa':'',
                'usuario':this.usu,
                'referencia':'0'};
            const response = await axios.post(process.env.VUE_APP_URL + '/api/inv/compraventa/nueva',parametros);
            const datos=response.data;
            //console.log(datos);
            if (datos=='si'){ 
                this.existe='si';    
                this.Msg='Producto Grabado !!!'
                this.msgbox=true;
                this.color='success';
                this.showSnack();
                this.disabledDatos=false;
                this.filledDatos=false;
                this.btnGrabar=false;
                this.nVentas++;
                this.total=this.total+(parseFloat(this.precio)*parseFloat(canti));
                this.venta=parseFloat(this.total)+Math.round((parseFloat(this.total)*parseFloat(this.impuesto)/100));
                this.listaproducto='';
                this.listanombre='';
                this.listacodigo='';
                this.miprecio='';
                this.precio='';
                this.bodega='';
                this.tStock='';
                this.SkuItem='';
                this.cantidad='';
                //this.observa='';
                this.listabarra='';
                this.txtMedida='';
                //enviamos el foco si check es true
                if(this.checkbox===true){
                    var elemento = document.getElementById("barr");
                    elemento.focus();
                }
            } 
            if (datos=='error'){
                this.Msg='Conexion Inestable, intentelo mas tarde'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
                this.newVenta();
                this.existe=''  
            }         
        }
    }
}
</script>